<template>
  <div>
    <!-- Remove Project -->
    <v-card>
      <v-card-title>Remove Project</v-card-title>

      <v-card-text>
        <v-form ref="removeUAform">
          <ul>
            <li>User will not be notified upon task removal</li>
            <li>User's project data will be removed completely</li>
          </ul>

          <AssignmentForm
            :projects="projects"
            :users="users"
            :assignment="removeUAData"
          />
        </v-form>

        <v-btn class="primary mt-3" @click="removeProject">Remove Project</v-btn>
      </v-card-text>
    </v-card>

    <v-card class="mt-4">
      <v-card-title>Assign Project</v-card-title>

      <v-card-text>
        <ul>
          <li>User will be notified upon project assignment via Push Notification</li>
          <li>A notification will be added to the Mobile Notification Center</li>
          <li>Upload at max 1000 users per Excel file</li>
          <li>Assign project to user might takes time to execute as it is assign to user one by one</li>
          <li>If the project is unable to assign to a user given a list, the operation will be stopped. Previously assigned users are not effected.</li>
          <li>If the first activity of the project has a greater start date than today, the first task will be assigned as scheduled task</li>
        </ul>

        <v-form ref="assignUAform">
          <AssignmentForm
            :projects="projects"
            :users="users"
            :assignment="assignUAData"
          />
        </v-form>

        <v-btn class="primary mt-3" @click="assignProject">Assign Project</v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";
import AssignmentForm from "./form.vue"

const service = new RestResource();
export default {
  components: {
    AssignmentForm
  },
  data() {
    return {
      assignUAData: {
        uploadFromFile: false,
        projectId: null,
        userIds: [],
      },
      removeUAData: {
        uploadFromFile: false,
        projectId: null,
        userIds: []
      },
      users: [],
      projects: [],
    };
  },

  mounted() {
    this.fetchUserIds();
    this.loadProjects();
  },

  methods: {
    fetchUserIds() {
      service.fetchUserIds().then(r => {
        this.users = r.data;
      });
    },

    loadProjects() {
      service.fetchProjects().then(r => {
        this.projects = r.data;
      });
    },

    removeProject() {
      if (this.$refs.removeUAform.validate()) {
        this.$setLoader()

        service.removeUserActivities(this.removeUAData).then(res => {
          this.$showSuccessMessage(res.data)
        })
      }
    },

    async assignProject() {
      if (this.$refs.assignUAform.validate()) {
        this.$setLoader()

        for (let userId of this.assignUAData.userIds) {
          await service.assignProject({
            projectId: this.assignUAData.projectId,
            userId,
            assignedBy: this.$store.state.userProfile.name,
          })
        }

        this.$showSuccessMessage({ message: `Succesfully assigned to ${this.assignUAData.userIds.length} users` })
      }
    }

  }
};
</script>