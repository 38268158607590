<template>
  <div>
    <v-checkbox v-model="assignment.uploadFromFile"
      label="Upload MobileUserIds From File"
    ></v-checkbox>

    <v-autocomplete
      :items="projects"
      item-text="name"
      item-value="_id"
      autocomplete
      v-model="assignment.projectId"
      label="Choose Project"
      persistent-hint
      hint="Select the project to be assigned"
      :rules="[v => !!v || 'Project is required']"
    ></v-autocomplete>

    <v-file-input v-if="assignment.uploadFromFile == true"
      label="Upload Users Bulk"
       persistent-hint
       hint="The format of the file should be csv. The header in the file should be mobileUserId"
      accept=".csv"
      @change="selectFile"
      :rules="[v => !!v || 'File is required']"
    />

    <v-autocomplete v-else
      v-model="assignment.userIds"
      :items="users"
      label="Select UserIds"
      multiple
      item-text="_id"
      item-value="_id"
      chips
      persistent-hint
      hint="Select the userIds you want to assign the project to"
      :rules="[v => !!v && v.length > 0 || 'UserIds is required']"
    ></v-autocomplete>
  </div>
</template>

<script>
var csvjson = require("csvjson");

export default {
  inject: {
    $validator: '$validator'
  },
  props: {
    assignment: Object,
    users: Array,
    projects: Array,
  },

  methods: {
    selectFile(e) {
      if (e) {
        const self = this;
        const file = e;
        this.buttonText = file.name;

        let reader = new FileReader();
        reader.onload = () => {
          self.importedData = reader.result;

          let csvData = csvjson.toObject(reader.result, {});
          this.assignment.userIds = this.retreiveMobileUserIds(csvData);
        };
        reader.readAsText(file);
      }
    },

    retreiveMobileUserIds(csvData) {
      let processedUserIds = [];
      csvData.forEach(row => {
        if (row.mobileUserId != null && row.mobileUserId != undefined && row.mobileUserId != "") {
          processedUserIds.push(row.mobileUserId);
        }
      });

      return Array.from(new Set(processedUserIds));
    }
  }
};
</script>